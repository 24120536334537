 <template>
  <section class="event-edit">
    <h2 class="title">修改事件</h2>
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="120px">
      <el-form-item label="标题:" prop="title">
        <el-input v-model="form.title" />
      </el-form-item>
      <el-form-item label="原文地址：" prop="address">
        <el-input v-model="form.address" />
      </el-form-item>
      <el-form-item label="发布时间：" prop="publish" class="publish">
        <el-date-picker v-model="form.publish" type="datetime"></el-date-picker>
      </el-form-item>
      <el-form-item label="采集时间：" prop="collect" class="collect">
        <el-date-picker v-model="form.collect" type="datetime"></el-date-picker>
      </el-form-item>
      <el-form-item label="自定义媒体：" prop="custom">
        <el-input v-model="form.custom" />
      </el-form-item>
      <el-form-item label="其它选项：">
        <span class="el-dropdown-link" @click="dropUp" v-if="openFilter">
          收起<i class="el-icon-arrow-up el-icon--right"></i>
        </span>
        <span class="el-dropdown-link" @click="dropUp" v-else>
          展开<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
      </el-form-item>
      <el-form-item label="正负面：" class="plusMinus" v-if="openFilter" prop="plusMinus">
        <el-select v-model="form.plusMinus">
          <el-option
            key="1"
            label="1"
            value="1">
          </el-option>
          <el-option
            key="2"
            label="2"
            value="2">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分类：" class="sort" v-if="openFilter" prop="sorts">
        <el-select v-model="form.sorts">
          <!-- <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option> -->
        </el-select>
      </el-form-item>
      <el-form-item label="标签：" class="tag" v-if="openFilter" prop="tag">
          <el-select v-model="form.tag">
            <!-- <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option> -->
          </el-select>
        </el-form-item>
      <el-form-item label="摘要：" prop="abstract">
        <el-input type="textarea" :rows="3" v-model="form.abstract"></el-input>
      </el-form-item>
      <el-form-item label="正文:" class="required" prop="mainBody">
        <vue-ueditor-wrap v-model="form.mainBody" :config="myConfig"></vue-ueditor-wrap>
        <div class="warning" v-if="content">请输入正文内容</div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="medium" @click="save">保存</el-button>
        <el-button size="medium" @click="toBack">返回</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
  components: {
    VueUeditorWrap
  },
  data () {
    return ({
      id: '',
      openFilter: true,
      content: false,
      form: {
        title: '',
        address: '',
        publish: '',
        custom: '',
        collect: '',
        plusMinus: '',
        sorts: '',
        tag: '',
        abstract: '',
        mainBody: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请选择原文地址', trigger: 'blur' }
        ],
        publish: [
          { type: 'date', required: true, message: '请选择发布时间', trigger: 'change' }
        ],
        collect: [
          { type: 'date', required: true, message: '请选择采集时间', trigger: 'change' }
        ],
        custom: [
          { required: true, message: '请输入媒体方式', trigger: 'blur' }
        ]
      },
      myConfig: {
        serverUrl: '',
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: '/UEditor/'
      }
    })
  },
  mounted () {
    var len = this.$route.path.split('/').length
    this.id = this.$route.path.split('/')[len - 1]
  },
  methods: {
    load () {
      // 加载数据内容
    },
    validate () {
      this.content = false
      if (!this.form.mainBody && this.form.mainBody.length === 0) {
        this.content = true
      }
    },
    save () {
      this.$refs['form'].validate((valid) => {
        this.validate()
        if (valid && this.content !== true) {
          console.log('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    toBack () {
      this.$router.replace('/eventEvolution/evolutionDetails')
    },
    // 收起展开
    dropUp () {
      this.openFilter ? (this.openFilter = false) : (this.openFilter = true)
    }
  }
}
</script>

<style lang="scss">
.event-edit {
  width: 100%;
  min-height: 100%;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(53,64,82,0.06);
  border-radius: 10px;
  padding: 40px 30px;
  .el-form {
    padding: 0 30px;
    .el-form-item {
      &.required {
        label {
          &::before {
            content: '*';
            color: red;
            padding-right: 2px;
          }
        }
      }
      &.publish,&.collect,&.plusMinus,&.sort,&.tag {
        display: inline-block;
      }
      &.publish,&.collect {
        width: 50%;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }
      .warning {
        color: #f56c6c;
        font-size: 12px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 100%;
        left: 0;
      }
    }
  }
}
</style>
